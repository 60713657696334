export default function Privacy() {
  return (
    <div className="flex-1 h-full w-full">
      <div className="relative isolate">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-orange-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-orange-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-20 lg:flex lg:items-center lg:gap-x-10 lg:px-8 ">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
              <h1 className="mt-4 max-w-lg text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
                Privacy Policy for Frami App
              </h1>
            </div>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Effective date: July 30, 2023
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Ridwan Raji ("I","us", "we", or "our") operates the Frami mobile
              application (hereinafter referred to as the "Service").
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data. The Privacy Policy
              for Frami App has been created with the help of{" "}
              <a
                href="https://www.termsfeed.com/privacy-policy-generator/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy Generator
              </a>
              .
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We do not collect any personal information from you. We do not
              sell, trade, or otherwise transfer to outside parties your
              personally identifiable information. We do not use cookies.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              If you have any questions about this Privacy Policy, please
              contact us:{" "}
              <a
                className="text-orange-600 font-semibold"
                href="mailto:ridwan@colouredhouse.com"
              >
                ridwan@colouredhouse.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
