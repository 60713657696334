import React from "react";
import logo from "./../assets/logo.png";
import screenshot from "./../assets/screenshot.png";

export default function Home() {
  return (
    <div className="flex-1 h-full w-full">
      <div className="relative isolate">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-orange-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-orange-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-20 lg:flex lg:items-center lg:gap-x-10 lg:px-8 ">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
              <img
                className="h-16 w-auto sm:h-24"
                src={logo}
                alt="Frami logo"
              />
            </div>
            <h1 className="mt-4 max-w-lg text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
              Enhance Your Photos with{" "}
              <span className="text-orange-600 font-bold ">Frami.</span>
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              🎨 AI-Generated Color Palettes: Let{" "}
              <span className="text-orange-600 font-semibold">Frami</span>'s
              advanced machine learning analyze your images and generate
              personalized color palettes.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              🖼️ Custom Borders: Add a touch of style, class and personality to
              your images with{" "}
              <span className="text-orange-600 font-semibold">Frami</span>. From
              elegant and subtle designs to bold and vibrant patterns, choose
              the border that best complements your photo and showcases your
              artistic vision.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="https://apps.apple.com/ca/app/frami-image-frame-borders/id1670764998"
                className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Download Frami for iOS
              </a>
            </div>
            <div className="mt-6 text-sm text-gray-500">
              or scan the QR code below
              <img
                className="mt-2 w-24 h-24"
                src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/4d185d95a1a6f47827d2c3b06d2825b8/d924d7b3df7ef47620823f90c8704d51.png"
                alt="QR code"
              />
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src={screenshot} alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
